// src/components/Button.js
import React, { useEffect } from "react";

const Button = ({ children, onClick }) => {
  useEffect(() => {
    console.log("Button.js");
  }, []);
  return (
    <>
      <button
        onClick={onClick}
        style={{
          backgroundColor: "blue",
          color: "white",
          padding: "10px 15px",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
        }}
      >
        {children}
      </button>
    </>
  );
};

export default Button;
