import React, { useEffect } from "react";

const DeleteIcons = () => {
  useEffect(() => {
    window.addEventListener("load", () => {
      console.log("Page chargée");
      const deleteIcons = () => {
        const elements = document.querySelectorAll(
          "#gatsby-focus-wrapper > div > header > div:nth-child(2) > div > a"
        );
        console.log(elements);
        elements.forEach((el) => {
          el.remove();
        });
      };

      deleteIcons();
      returnToSite();
      addDiscordIcon();
    });
    const deleteIcons = () => {
      const elements = document.querySelectorAll(
        "#gatsby-focus-wrapper > div > header > div:nth-child(2) > div > a"
      );
      console.log(elements);
      elements.forEach((el) => {
        el.remove();
      });
    };

    const addDiscordIcon = () => {
      const elements = document.querySelectorAll(
        "#gatsby-focus-wrapper > div > header > div:nth-child(2) > div"
      );
      console.log(elements);
      elements.forEach((el) => {
        el.innerHTML += `<a href="https://discord.com/invite/acqWQzAQXM?ref=i26oH3qYcO5szR" target="_blank" rel="noreferrer"><img src="https://img.icons8.com/color/48/000000/discord-logo--v2.png" style="height: 25px;"/></a>`;
        el.style.height = "25px";
      });
    };

    const returnToSite = () => {
      const elements = document.querySelectorAll(
        "#gatsby-focus-wrapper > div > header > div:nth-child(2) > div"
      );
      console.log(elements);
      elements.forEach((el) => {
        el.innerHTML += `<a href="https://www.vintup.net/" target="_blank" rel="noreferrer"><img src="https://img.icons8.com/color/48/000000/return--v2.png" style="height: 25px; margin-right: 20px;"/></a>`;
      });
    };

    deleteIcons();
    returnToSite();
    addDiscordIcon();
  }, []);

  return <></>;
};

export default DeleteIcons;
