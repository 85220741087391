export default {
  text: "#000",
  background: "#fff",
  primary: "#186b1c",
  secondary: "#114a14",
  sidebar: "#b7cfb8",
  borderColor: "rgba(0, 0, 0, 0.15)",
  modes: {
    shadow: {
      text: "#fff",
      background: "#182952",
      primary: "#f638dc",
      secondary: "#ff7976",
      sidebar: "#101d3c",
      borderColor: "rgba(255, 255, 255, 0.15)",
    },
    light: {
      text: "#000",
      background: "#fff",
      primary: "#186b1c",
      secondary: "#114a14",
      sidebar: "#b7cfb8",
      borderColor: "rgba(0, 0, 0, 0.15)",
    },
    dark: {
      text: "#fff",
      background: "hsl(230,25%,18%)",
      primary: "#114a14",
      secondary: "#8ab18c",
      sidebar: "hsla(230, 20%, 0%, 20%)",
      borderColor: "rgba(255, 255, 255, 0.15)",
    },
  },
};
